.form-control::-webkit-input-placeholder { color: #878686; }  /* WebKit, Blink, Edge */
.form-control:-moz-placeholder { color: #878686; }  /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder { color: #878686; }  /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder { color: #878686; }  /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder { color: #878686; }  

.contactUsContainer{
    min-height: 400px;
    margin-top: 150px;
    margin-bottom: 150px;
}

.contactFormCard{
  transform: scale(1.3)
}

.contactUsMapContainer{
  height: 500px;
}

@media screen and (max-width : 992px) {
 
 }
 
 @media only screen and (max-width: 768px) {
.contactUsContainer{
    height: auto;
    margin-top: 0px;
    margin-bottom: 0px;
  }
    .contactFormCard{
      transform: scale(1)
    }
    .contactUsMapContainer{
      height: 300px;
    }
 }
 
 @media screen and (max-width : 360px) {
.contactUsContainer{
    height: auto;
    margin-top: 0px;
    margin-bottom: 0px;
  }
    .contactFormCard{
      transform: scale(1)
    }
    .contactUsMapContainer{
      height: 300px;
    }
 }