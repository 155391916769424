.cardimg {
  width: 196px;
  height: 142px;
  left: 120px;
  top: 241px;
  background: #ffffff;
  border: 1px solid #878686;
  box-sizing: border-box;
  border-radius: 8px;
}

.carousell {
  width: 338px;
  height: 241px;
  /* background-color: grey; */
  color: white;
  padding: 1rem;
  float: right;
  margin-left: 1rem;
}

.merchantsListCard{
}

.textTruncateContainer {
  /* width: 250px; */
}
.textTruncateContainer p {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}