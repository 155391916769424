.counter-sec .sub-title,
.blog-holder h4 a:hover,
#footer .f-nav a:hover,
.abt-sec .title,
.widget.coupon-submit-widget .icon,
.pagination a:hover,
.service-sec.style2 .service .icon,
#footer .footer-nav a:hover,
#footer .contact-list a:hover,
#footer .recent-tweet a:hover,
.offer-holder .col .btn-primary .code,
#header.header2 #nav > ul > .active > a,
#header.header2 #nav > ul > li > a:hover,
.post-block .heading3 a:hover,
.team .img-holder .socail-network a:hover,
.banner .search-form .btn-icon:hover,
.latest-coupon .coupon-box h3 a:hover,
.subscribe-sec .subscribe-form .sub-btn:hover,
#header .header-top .align-left a:hover,
.latest-coupon .coupon-box .list-show a:hover,
#header.header2 .align-left a:hover,
.widget .latest-news-list h3 a:hover,
.widget .popular-list a:hover,
.widget .category-list a:hover,
.contact-sec .contact-list a:hover,
#content .header-holder .store-txt:hover,
#footer .f-nav a:hover,
.contact-list2 .icon,
.contact-list2 a:hover,
.register-holder .register-form .form-check a:hover,
#content .comments-block .commment-area h3 a:hover,
.widget .latest-news-list .news-nav a:hover,
.service-sec .service .read-more:hover {
  color: #218c44; }

#back-top:hover,
#header .cart .num,
#nav > ul > li > a:hover,
.abt-sec .btn-primary:hover,
#header .nav-opener,
.post-block .time,
.sub-form .btn-primary:hover,
#content .abc-list a:hover,
.post-block .btn-primary,
#nav > ul > .active > a,
#footer .tags a:hover,
.trending-coupon .coupon-box .btn-primary,
#nav .drop-down > li > a:hover,
#nav > ul > li:hover > a,
.team.v2 .socail-network a:hover,
.register-holder .register-form .btn-primary:hover,
.comments-form .leave-form .btn-primary:hover,
.widget .tags-list a:hover,
#footer .newsletter-form .btn-primary:hover,
.banner.banner2 .search-form .sub-btn:hover,
.widget .search-form .sub-btn,
.team .img-holder .socail-network,
.store-sec .btn-primary:hover,
.callout-sec .btn-primary:hover,
#footer .socail-network a:hover,
.blog-holder .btn-primary,
.post-detail .footer .btn-primary,
.contact-sec .contact-form .btn-primary,
.blog-holder .img-holder .time,
.offer-holder .col .btn-primary,
#header .search-form .sub-btn:hover,
#header .nav-holder .btn-primary:hover,
.feature-sec .feature-box .btn-primary,
.offer-holder .col:hover .offer,
.latest-coupon .coupon-box .btn-primary {
  background: #218c44; }

.pagination a:hover,
.team.v2 .socail-network a:hover,
.offer-holder .col:hover .offer,
.offer-holder .col .btn-primary .code {
  border-color: #ffb300; }
