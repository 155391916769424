@import "~antd/dist/antd.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bg-success-alt {
  background-color: #218c44 !important;
  color: white !important;
}

.bg-primary-alt {
  background-color: #00446b !important;
  color: white !important;
}

.text-error {
  color: #dc3545;
  font-size: 12px;
  float: right;
}
