.cards {
  width: 300px;
}
.instructions {
  font-size: 1rem;
  border-radius: 0.5rem;
  background: #fff;
  color: #000;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
  font-style: italic;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.facheck {
  color: green;
}

.faXcheck {
  color: red;
}

.log-sec input,
.formTextColors {
  color: #878686 !important;
}

.cardHeight {
  min-height: 75vh;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
