.CategoriesCard {
  width: 180px;
  height: 230px;
  display: flex;
  flex-shrink: 3;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px 50px !important;
}
.categoryContainer {
  overflow-x: auto;
}

.categoryContainer::-webkit-scrollbar-corner {
  background: "rgba(0,0,0,0.5)";
}
.categoryContainer::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.categoryContainer::-webkit-scrollbar-track {
  background: "#f8f8f8";
  border-radius: "12px";
}
.categoryContainer::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 20px;
  border: "3px solid #f8f8f8";
}
.categoryText {
  text-align: center;
  cursor: pointer;
  height: 45px;
  width: 240px;
  background-image: linear-gradient(to right, #e4ecf1, rgba(228, 236, 241, 0));
  transition: all 0.35s ease-in-out;
}
.categoryText:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.categoryTextMobile {
  color: #00446b;
  font-size: 14px;
  border-radius: 11px;
  background-image: linear-gradient(to right, rgb(228, 236, 241), rgba(228, 236, 241, 0));

  white-space: nowrap;
  padding: 11px;
}

@media screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
  .CategoriesCard {
    width: 40px;
    height: 150px;
  }
}

@media screen and (max-width: 360px) {
  .CategoriesCard {
    width: 40px;
    height: 150px;
  }

  .bannersubheading {
    display: none;
  }
}
