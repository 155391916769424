.thankyou {
    font-family: Jim Nightshade;
    font-size: 50px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #218C44;
}

.iconBg{
    padding: 10;
    border-radius: 50%
  }

.inputBorder{
    border-color: #c4c4c4 !important;
}
