#sidebar {
  /* background-color: #222e3c; */
  font-size: 1rem !important ;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: auto 100% !important;
  background-position: left top;
}
.sidebarContent {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.navbar {
  background-color: #222e3c;
  font-size: 0.85rem !important ;
}

#sidebar span {
  margin-right: 10px;
}

#sidebar a span {
  color: white;
}

.sidebar-link {
  border-radius: 8px;
  margin-bottom: 1rem !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

/* .sidebar-link:hover,
.sidebar-link:active {
  background-color: #4b49ac;
} */

.sidebar-link:hover span,
.sidebar-link:active span {
  color: white !important;
}

.Main {
  display: flex;
  flex-direction: row;
  overflow-x: hidden !important;
  background-color: white;
  min-height: 100vh;

  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
}

.hover-cursor:hover {
  cursor: pointer;
}

.link-hover:hover {
  /* background-color: #d1cece !important */
  /* background-color: #4b49ac !important; */
  background-color: #218c44 !important;
}

.btn-primary {
  background-color: #4b49ac !important;
  color: white !important;
}
