.my-container {
  padding: 108px 0px;
}
.merchant_profile {
  align-items: center;
  display: flex;
  margin-bottom: 5px;
  max-width: 250px;
  width: 222px;
  height: 187px;
  border-radius: 6px;

  border: 1px solid #c4c4c4;
}
.review-container {
  display: flex;
  justify-content: space-between;
  padding: 70px 113px;
  flex-wrap: wrap;
}
.review_inputBox {
  height: 223px;
}
/* .ant-input-textarea textarea::placeholder {
  font-size: 20px;
} */
.merchant_profile_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.merchant_input_container {
  /* height: 223px; */
  width: 720px;
}
.review_inputBelow_text {
  font-size: 16px;
  font-weight: 500;
  color: #878686;
}
.inner_text {
  font-size: 16px;
  font-weight: 500;
  color: #00446b;
  margin: 0px 5px;
  cursor: pointer;
}
.review-merchant-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.merchant_profile_name {
  /* font-size: 32px; */
  font-size: 20px;
  font-weight: 500;
  /* max-width: 220px; */
}
.summary {
  border: 2px solid #d9d9d9;
  padding: 20px;
}
.user-reviews {
  border: 2px solid #d9d9d9;
  padding: 20px;
}
.review-count {
  font-size: 18px;
  font-weight: 500;
}
.summaryText {
  font-size: 16px;
  font-weight: 400;
  padding: 0px 18px;
  line-height: 19.36px;
  width: 20%;
}
.review-date {
  color: #00446b;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
}
.like {
  display: flex;
  align-items: center;
}
.review-merchant-name {
  font-size: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #00446b;
}
.review-user-rating {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.review-user-rating img {
  width: 140px;
  height: 25px;
  margin-left: -20px;
}
.review-user-rating span {
  font-size: 17px;
  font-weight: 500;
}
.review-response {
  padding: 10px 0px;
  font-size: 16px;
  font-weight: 500;
}
.trip_image img {
  width: 165px;
  height: 30px;
}
@media (max-width: 480px) {
  .my-container {
    padding: 20px 0px;
  }
  .review-count {
    font-size: 14px;
  }
  .trip_image img {
    width: 120px;
    height: 21px;
  }
  .merchant_profile {
    display: flex;
    margin-bottom: 5px;
    width: 83px;
    height: 50px;
    border-radius: 6px;
    border: 1px solid #c4c4c4;
    overflow: hidden;
  }
  .review-container {
    display: flex;
    justify-content: space-between;
    padding: 24px 25px;
    flex-wrap: wrap;
  }
  .merchant_profile_container {
    display: flex;
    flex-direction: row;
  }
  .merchant_profile_name {
    font-size: 15px;
    font-weight: 500;
  }
  .review-merchant-info {
    margin-left: 20px;
  }
  .review_inputBox {
    height: 97px;
  }
  .review_inputBelow_text {
    font-size: 12px;
  }
  .inner_text {
    font-size: 12px;
  }
  .merchant_input_container {
    /* height: 223px; */
    width: 720px;
    padding-top: 20px;
  }
  .summaryText {
    width: 187px;
  }
}
