.upper-container {
  box-shadow: rgb(33 35 38 / 10%) 0px 10px 10px -10px;
  padding: 0px 10px 24px;
  font-family: "Roboto";
}
.profile-business-name {
  font-size: 40px;
  color: #00446b;
}
.profile-image-cont {
  width: 250px;
  height: 200px;
  position: relative;
}
.main-container {
  display: flex;
  background-color: #f0f0f0;
  /* flex-wrap: wrap; */
}
.map-container {
  /* width: 400px; */
  /* width: 35%; */
  /* width: 100%; */
  width: 700px;
  height: 407px;
  padding: 20px;
}
.profile-container {
  /* width: 65%;
  padding: 0px 0px 35px 35px; */
  width: 100%;
  padding: 10px 11px 35px 35px;
}
.profile-title {
  display: flex;
  justify-content: space-between;
}
.gallery-images {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0px;
}
.gallery-images .ant-image {
  margin-bottom: 26px;
  overflow: hidden;
  border-radius: 10px;
}
.gallery-images .ant-image img {
  width: 336px;
  height: 200px;
  object-fit: cover;
  background: #a4a4a4;
}
.showMore {
  display: flex;
  justify-content: center;
  width: 100%;
}
.review {
  display: flex;
  align-items: center;
  color: #218c44;
  justify-content: flex-end;
  cursor: pointer;
}
.review img {
  width: 165px;
  height: 30px;
}
.review span {
  font-size: 18px;
}
.image1 {
  width: 50%;
  max-height: 222px;
  max-width: 374px;
}
.image2 {
  width: 100%;
}
.react-images__view img {
  height: 90vh;
  width: 90vw;
  object-fit: contain;
}
.react-images__footer__count {
  display: none;
}
@media (max-width: 480px) {
  .review img {
    width: 109px;
    height: 20px;
    margin-left: -11px;
  }
  .review span {
    font-size: 13px;
  }
  .gallery-images {
    justify-content: center;
  }
  .review {
    justify-content: start;
  }
  .profile-about {
    padding: 5px;
  }
  .profile-about div {
    font-size: 12px;
  }
  .profile-business-name {
    font-size: 20px;
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .main-container {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .map-container {
    width: auto;
  }

  .profile-container {
    /* margin-top: 33px; */
    width: 100%;
    padding: 6px;
  }
}
