.categorySearchBar {
  border: none !important;
  width: 100% !important;
  outline: none !important;
}
.keywordClass input:nth-child(1) {
  font-size: 15px;
}

.categorySearchBar .form-control {
  border: none !important;
}

/* .pac-item > span:last-child {
  position: relative;
}
.pac-item > span:last-child::before {
  position: absolute;
  content: "";
  right: 0px;
  top: 0;
  height: 12px;
  width: 28px;
  background: #fff;
}
.pac-item:hover span:last-child::before {
  background: #fafafa;
}
.pac-container::after {
  display: none;
} */
