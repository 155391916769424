.carousel-indicators {
  bottom: -40px !important;
}

.carousel-inner {
  margin-bottom: 70px !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none !important;
}
.carousel-control-next-icon {
  display: none !important;
}
.carousel-control-prev-icon {
  display: none !important;
}
.merchantProfile-container {
  display: flex;
}
.merchant-profileImage {
  align-items: center;
  display: flex;
  margin-bottom: 5px;
  max-width: 250px;
  width: 250px;
}
.merchant-detail {
  padding: 0px 20px;
  width: 100%;
}
.merchantProfile-rating {
  display: flex;
  /* align-items: end; */
  justify-content: space-between;
}
.star-container {
  display: flex;
  color: #218c44;
  align-items: center;
  cursor: pointer;
}
.time {
  display: flex;
  justify-content: space-between;
}
