.lock {
  height: 30px;
  width: auto;
  margin-bottom: 1rem;
}

.instructions {
  font-size: 1rem;
  border-radius: 0.5rem;
  background: #fff;
  color: #000;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
  font-style: italic;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.facheck{
  color: green;
}

.faXcheck{
  color: red;
}