.elementsHeight
{
    height: 30px;
    line-height: 30px !important;
}

.searbarButton{
border: none;
width: 60px;}

.elementInput
{
    border:none !important;
    outline: none !important;
}

.elementInput .form-control{
    border:none !important;
    outline: none !important;
  }
textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}
