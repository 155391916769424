.MerchantsAndDeals{
    background-color: #F6F6F6;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    /* background-color: #fff; */
    /* border-color: #dee2e6 #dee2e6 #fff; */

}

@media screen and (max-width : 992px) {
}

@media only screen and (max-width: 768px) {
}

@media screen and (max-width : 360px) {
    .nav {
    flex-wrap: nowrap;
}
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {    }

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  }

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {  }