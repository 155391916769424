.emailOuter{
    width:100%;
  }
  .emailInner{
    width: 100%;
    margin-top: 10px;
    padding-left:20px;
    padding-right: 20px;
  }
  .header{
    text-align:center;
  }
  .header p{
    
  }
  .header button{
    background:#218C44;
    color:white;
    border-radius:4px;
    height:30px;
    border:none;
    padding: 8px;
  }
  .giveaway{
    display: flex;
    justify-content: space-evenly;
  }
  .weekly,.monthly,.travel{
    text-align:center ;
  }
  .content{
    text-align:center;
  }

  img {
    width : auto;
    height : 140px
  }
  @media screen and (max-width: 768px) {
  .email-inner{
    padding-left:10px;
    padding-right: 10px;
  }

  @media screen and (max-width: 768px) {
    img {
      width : 80%;
      height : auto;
    }

    .giveaway {
      flex-direction: column;
    }
  }
  }