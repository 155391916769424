.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #495057;
 background-color: #F6F6F6;
 border: none;
    /* border-color: #dee2e6 #dee2e6 #fff; */
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: #F6F6F6;
    border: none;
}

.nav-link {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

.nav-tabs {
    border: none;
}

@media only screen and (max-width: 768px) {
    .nav {
    flex-wrap: nowrap;
}
.nav-link {
    padding-bottom: 0 !important;
}
}

@media screen and (max-width : 360px) {
    .nav {
    flex-wrap: nowrap;
}
.nav-link {
    padding-bottom: 0 !important;
}
}

