.banner {
  height: 400px;
  background-size: cover !important;
}
.mobileInputClass {
  font-size: 13px;
}
.memberPoolFontSize {
  /* font-size: smaller; */
  font-size: 14px;
}

.bannersubheading {
  padding-left: 200px;
  padding-right: 200px;
}

.searbarContainer {
  display: flex;
  flex-basis: 70%;
  background-color: #fff;
  border-radius: 4px !important;
}

.poolContainer {
  display: flex;
  flex-basis: 100%;
  padding-left: 0px;
  padding-right: 0px;
}

.searbarButton {
  background-color: #00446b;
  border: none;
  width: 60px;
}

.pool {
  display: flex;
  flex: 0.5;
  justify-content: center;
}

.poolContent {
  display: flex;
  flex: 1;
  justify-content: space-around;
}

.searbarInput1 {
  display: flex;
  flex: 0.5;
  padding-right: 5px;
  z-index: 1777 !important;
}
.searbarInput2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  flex: 0.5;
  padding-left: 5px;
  max-width: 100%;
  /* overflow: hidden */
  z-index: 1500 !important;
}

.searbarInput {
  background-color: transparent !important;
  border: none !important;
}

@media screen and (max-width: 992px) {
  .bannersubheading {
    padding-left: 100px;
    padding-right: 100px;
  }
  .searbarContainer {
    flex-basis: 100%;
  }

  .poolContent {
    align-items: center;
  }
  .poolContent .poolIcon {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .banner {
    height: 250px;
  }
  .bannersubheading {
    display: none;
  }
  .searbarContainer {
    flex-basis: 100%;
  }

  .poolContent {
    align-items: center;
  }
  .poolContent .poolIcon {
    margin-right: 10px;
  }
}
@media (max-width: 480px) {
  .searbarInput12 .Banner_searbarInput {
    height: 32px;
  }
  .searbarInput22 {
    height: 32px;
  }
  .myInputClass {
    height: 32px;
    font-size: 13px;
  }
}

@media screen and (max-width: 360px) {
  .bannersubheading {
    display: none;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
