.merchant-container {
  display: flex;
  /* justify-content: center; */
}
.merchant-image {
  /* height: 50px; */
  width: 83px;
  border: 1px solid #dee2e6;
  border-radius: 0.3rem;
  /* margin-top: 20px; */
}
.merchant-info {
  padding: 0px 25px;
}
.date-section {
  display: flex;
  justify-content: space-between;
}
.gray-color {
  color: gray;
  font-weight: 400;
}
.merchant-rating {
  display: flex;
  align-items: center;
}
.address-size {
  font-size: 12px;
}
@media (max-width: 480px) {
  .merchant-rating {
    padding: 5px 0px;
  }
  .merchant-rating img {
    width: 82px;
  }
}
