.logo {
    height: 60px;
    width: auto;
}
.navbarspace {
    height: 86px;
}

.usericon, .usericon:focus {
    outline: none !important;
    box-shadow: none;
}
 
.navfont{
    font-size: 18px ;
}

@media screen and (max-width: 768px) {
    .logo {
        height : 50px; 
    }

    .navbarspace {
        height: 75px;
    }

    .sloganFont{
        font-size: .75em;
    }
}


@media (max-width: 280px) {    
    .sloganFont{
    font-size: .5em;
} }